













































import { PayloadState } from '@/types/types';
import Component from 'vue-class-component';
import { Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HelpDialog extends Vue {
  @Prop({ default: false }) drawer!: boolean;

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'help',
      state: false,
    };
  }
}
